<template>
  <v-container v-if="loaded">
    <!-- Header -->
    <v-row>
      <v-col cols="6" class="py-0 my-0 text-left">
        <span class="text-h2">Email Signature Generator</span>
      </v-col>
    </v-row>
    <!-- Form -->
    <div class="d-flex justify-center">
      <v-card style="width: 100%" class="pa-4">
        <v-row>
          <v-col cols="12" style="align-items: center; margin-bottom: 20px; padding: 20px">
            <v-row>
              <v-col style="min-width: 250px;">
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="userData.contactCard.firstName" @input="generateEmail(false)"
                      label="First Name" outlined hide-details></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="userData.contactCard.surname" @input="generateEmail(false)" label="Last Name"
                      outlined hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="userData.contactCard.jobTitle" @input="generateEmail(false)"
                      label="Title (optional)" outlined hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="userData.proNouns" @input="generateEmail(false)" label="Pronouns (optional)"
                      outlined hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="userData.title" @input="generateEmail(false)" label="Badge (optional)"
                      outlined hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="userData.note" @input="generateEmail(false)" label="Note (optional)" outlined
                      hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="userData.contactCard.phoneNumber" @input="generateEmail(false)"
                      label="Phone (optional)" outlined hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" class="text-center" style="margin-bottom: -11px;">
                  <v-col cols="4" class="d-flex justify-center">
                    <v-btn @click="selectImage('banner1')" style="
                      width: 100%;
                      padding-top: 20% !important; /* Adjust this value based on the aspect ratio of your image (height/width * 100) */
                      position: relative;
                      background-image: url('/assets/branding/signature/banners/banner1.png');
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: right;
                    " :selected="selectedImage === 'banner1'" class="banner-box"
                      :elevation="selectedImage === 'banner1' ? 20 : 0"
                      :class="{ 'selected-img': selectedImage === 'banner1' }"></v-btn>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center">
                    <v-btn @click="selectImage('banner2')" style="
                      width: 100%;
                      padding-top: 20% !important; /* Adjust this value based on the aspect ratio of your image (height/width * 100) */
                      position: relative;
                      background-image: url('/assets/branding/signature/banners/banner2.png');
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: right;
                    " :selected="selectedImage === 'banner2'" class="banner-box"
                      :elevation="selectedImage === 'banner2' ? 20 : 0"
                      :class="{ 'selected-img': selectedImage === 'banner2' }"></v-btn>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center">
                    <v-btn @click="selectImage('banner3')" style="
                      width: 100%;
                      padding-top: 20% !important; /* Adjust this value based on the aspect ratio of your image (height/width * 100) */
                      position: relative;
                      background-image: url('/assets/branding/signature/banners/banner3.png');
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: right;
                    " :selected="selectedImage === 'banner3'" class="banner-box"
                      :elevation="selectedImage === 'banner3' ? 20 : 0"
                      :class="{ 'selected-img': selectedImage === 'banner3' }"></v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center" class="text-center" style="margin-bottom: -11px;">
                  <v-col cols="4" class="d-flex justify-center">
                    <v-btn @click="selectImage('banner4')" style="
                      width: 100%;
                      padding-top: 20% !important; /* Adjust this value based on the aspect ratio of your image (height/width * 100) */
                      position: relative;
                      background-image: url('/assets/branding/signature/banners/banner4.png');
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: right;
                    " :selected="selectedImage === 'banner4'" class="banner-box"
                      :elevation="selectedImage === 'banner4' ? 20 : 0"
                      :class="{ 'selected-img': selectedImage === 'banner4' }"></v-btn>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center">
                    <v-btn @click="selectImage('banner5')" style="
                      width: 100%;
                      padding-top: 20% !important; /* Adjust this value based on the aspect ratio of your image (height/width * 100) */
                      position: relative;
                      background-image: url('/assets/branding/signature/banners/banner5.png');
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: right;
                    " :selected="selectedImage === 'banner5'" class="banner-box"
                      :elevation="selectedImage === 'banner5' ? 20 : 0"
                      :class="{ 'selected-img': selectedImage === 'banner5' }"></v-btn>
                  </v-col>
                </v-row>

              </v-col>
              <v-col lg="auto" class="d-flex">
                <div class="preview-box" ref="previewBox" style="width:414px;margin-left:auto;margin-right:auto" :style="{
    backgroundColor:
      previewSignature === ''
        ? 'var(--v-sidebarColorLight-lighten1)'
        : '',
    padding: selectedEmailClient === 'Rich Text' ? '19.89px' : ''
  }"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <div style="display: flex">
            <v-btn style="
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              " height="56" color="primary" class="white--text" @click="generateEmail" :disabled="!allowCopy">
              Copy Email Signature
            </v-btn>
            <v-select v-model="selectedEmailClient" :items="emailClients" @input="generateEmail(false)" style="
                width: 160px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              " label="Signature Type" outlined required></v-select>
          </div>
        </v-row>
        <v-alert
          v-if="deploymentEnvironment.includes('stage.deliverdooh') || deploymentEnvironment.includes('localhost')"
          color="var(--v-sidebarColorLight-lighten1)" justify="center" width="354px"
          style="margin-left:auto;margin-right:auto;text-align: center"><v-icon small
            style="margin-top: -2px;margin-right: 5px;">mdi-alert-circle-outline</v-icon>For testing only, use the
          production version for emails<br><v-btn href="https://deliver.dooh.com/#/dashboard/user/emailGenerator"
            style="margin-top: 10px" elevation="0">production version</v-btn></v-alert>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import UserController from "@/services/controllers/User";
export default {
  data: () => ({
    loaded: true,
    deploymentEnvironment: window.location.origin,
    userData: {
      title: "",
      proNouns: "",
      contactCard: {
        firstName: "",
        surname: "",
        email: "",
        phoneNumber: "",
        jobTitle: "",
        stakeholder: {
          friendlyName: "",
        },
      },
    },
    selectedImage: "banner1",
    selectedEmailClient: "Rich Text",
    emailClients: ["Rich Text", "Plain Text"],
    previewSignature: "",
    currentAddress: `Rose Court,<br>2 Southwark Bridge Road,<br>London,<br>SE1 9HS`
  }),

  mounted() {
    this.getUser().then(() => {
      this.generateEmail(false);
    });
  },
  computed: {
    allowCopy() {
      return (
        this.userData.contactCard.firstName &&
        this.userData.contactCard.surname
      );
    }
  },

  methods: {
    updatePreview() {
      this.$nextTick(() => {
        this.$refs.previewBox.innerHTML = this.previewSignature;
      });
    },
    selectImage(banner) {
      this.selectedImage = banner;
      this.generateEmail(false);
    },
    generateEmail(displaySnackbar = true) {
      // Check if User data is not empty
      if (!this.allowCopy) {
        this.previewSignature = "";
        this.updatePreview();
        this.$root.$emit(
          "snackbarError",
          "Please fill in all required fields."
        );
        return;
      }
      // Get URL origin
      const urlOrigin = window.location.origin + "/assets/branding/signature";

      let emailSignature = "";

      if (this.selectedEmailClient === "Rich Text") {
        // Generate Rich Text signature (table-based)
        const table = document.createElement('table');
        table.width = '375px';
        table.cellPadding = '0';
        table.cellSpacing = '0';
        table.style.borderCollapse = 'collapse';
        table.style.msoTableLspace = '0pt';
        table.style.msoTableRspace = '0pt';
        table.style.fontFamily = 'Arial,sans-serif';
        table.style.color = '#043c4b';

        // Helper function to create and append a row
        const createRow = (content, styles = {}) => {
          const tr = document.createElement('tr');
          const td = document.createElement('td');
          td.innerHTML = content;
          Object.assign(td.style, styles);
          tr.appendChild(td);
          table.appendChild(tr);
        };

        // Full Name
        createRow(`<b>${this.userData.contactCard.firstName} ${this.userData.contactCard.surname}</b>`, {
          fontFamily: 'Arial,sans-serif',
          color: '#1aa7c0',
          fontSize: '10.5pt',
          paddingLeft: '0px',
          paddingTop: '10px',
          paddingBottom: '5px'
        });

        // Job Title
        if (this.userData.contactCard.jobTitle) {
          createRow(this.userData.contactCard.jobTitle, {
            fontFamily: 'Arial,sans-serif',
            color: '#043c4b',
            fontSize: '10.5pt',
            paddingBottom: '5px',
            paddingLeft: '0px'
          });
        }

        // Pronouns
        if (this.userData.proNouns) {
          createRow(`'${this.userData.proNouns}'`, {
            fontFamily: 'Arial,sans-serif',
            color: '#043c4b',
            fontSize: '10.5pt',
            paddingLeft: '0px'
          });
        }

        // Title
        if (this.userData.title) {
          createRow(`<i>${this.userData.title}</i>`, {
            fontFamily: 'Arial,sans-serif',
            color: '#4296f8',
            fontSize: '9pt',
            paddingLeft: '0px'
          });
        }

        // Note
        if (this.userData.note) {
          createRow(`${this.userData.note}`, {
            fontFamily: 'Arial,sans-serif',
            fontSize: '8pt',
            paddingLeft: '0px',
            width: '375px',
            maxWidth: '375px'
          });
        }

        // Logo
        createRow(`<a href="https://www.dooh.com" style="border: none; text-decoration: none;"><img width="150px" height="56px" alt="DOOH.COM" src="${urlOrigin}/logo/DOOH.apng" style="border:0px none"></a>`, {
          paddingLeft: '0px'
        });

        // Address
        createRow(this.currentAddress, {
          fontFamily: 'Arial,sans-serif',
          color: '#043c4b',
          fontSize: '10.5pt',
          paddingLeft: '0px'
        });

        // Mobile
        if (this.userData.contactCard.phoneNumber) {
          createRow(`
            <span style="color:rgb(0,167,193)">M:</span>
            <a href="tel:${this.userData.contactCard.phoneNumber.replace(/[^\d\+]/g, "")}" style="font-size:10.5pt; font-family:Arial,sans-serif; color:#043c4b; text-decoration: none !important;">
              <span style="font-size:10.5pt; font-family:Arial,sans-serif; color:#043c4b; text-decoration: none !important;">${this.userData.contactCard.phoneNumber}</span>
            </a>
          `, {
            fontFamily: 'Arial,sans-serif',
            color: '#043c4b',
            fontSize: '10.5pt',
            paddingLeft: '0px',
            paddingTop: '10px'
          });
        }

        // Banner
        createRow(`<a href="https://www.dooh.com" style="border: none; text-decoration: none;"><img width="375px" height="91px" alt="banner" src="${this.getBannerUrl()}" style="border:0px none">`, {
          paddingTop: '10px',
          paddingLeft: '0px',
          paddingBottom: '5px'
        });

        // Social Media Links
        createRow(`
          Follow us on:
          <a href="http://www.x.com/WeAreDOOH" style="border: none; text-decoration: none;"><img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="X" src="${urlOrigin}/socials/x-2.png"></a>
          <span style="font-size: 14pt; padding-left: 10px; padding-right: 10px; vertical-align: middle;">•</span>
          <a href="https://www.linkedin.com/company/doohcom/" style="border: none; text-decoration: none;"><img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="LinkedIn" src="${urlOrigin}/socials/linkedin.png"></a>
          <span style="font-size: 14pt; padding-left: 10px; padding-right: 10px; vertical-align: middle;">•</span>
          <a href="https://www.instagram.com/dooh_com/" style="border: none; text-decoration: none;"><img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="Instagram" src="${urlOrigin}/socials/instagram.png"></a>
        `, {
          fontFamily: 'Arial,sans-serif',
          color: '#043c4b',
          textAlign: 'left',
          paddingLeft: '0px',
          paddingTop: '10px',
          fontSize: '10.5pt'
        });

        // Disclaimer
        createRow(`
          This e-mail message is confidential, intended only for the named recipient(s) above and may contain information that is privileged or exempt from disclosure under applicable law. If you have received this message in error, or are not the named recipient(s), please immediately notify the sender via reply email and delete this email message from your computer.
        `, {
          fontFamily: 'Arial,sans-serif',
          color: '#043c4b',
          paddingLeft: '0px',
          paddingTop: '10px',
          fontSize: '9.5px',
          width: '375px',
          maxWidth: '375px',
        });

        emailSignature = table.outerHTML;
      } else if (this.selectedEmailClient === "Gmail/Outlook") {
        // Generate Gmail/Outlook signature (div-based)
        const emailSignatureDiv = document.createElement("div");
        emailSignatureDiv.style.margin = "0";
        emailSignatureDiv.style.padding = "10px";
        emailSignatureDiv.style.fontSize = "10.5pt";
        emailSignatureDiv.style.fontFamily = "Arial, sans-serif";
        emailSignatureDiv.style.color = "#043c4b";

        // Add the full name
        const fullNameDiv = document.createElement("div");
        fullNameDiv.innerHTML = `<b>${this.userData.contactCard.firstName} ${this.userData.contactCard.surname}</b>`;
        fullNameDiv.style.fontFamily = "Arial, sans-serif";
        fullNameDiv.style.color = "#1aa7c0";
        fullNameDiv.style.fontSize = "10.5pt";
        fullNameDiv.style.paddingLeft = "0px";
        fullNameDiv.style.paddingTop = "10px";
        fullNameDiv.style.paddingBottom = "5px";
        emailSignatureDiv.appendChild(fullNameDiv);

        // Add the title
        if (this.userData.contactCard.jobTitle) {
          const titleDiv = document.createElement("div");
          titleDiv.textContent = this.userData.contactCard.jobTitle;
          titleDiv.style.fontFamily = "Arial, sans-serif";
          titleDiv.style.color = "#043c4b";
          titleDiv.style.fontSize = "10.5pt";
          titleDiv.style.paddingBottom = "5px";
          titleDiv.style.paddingLeft = "0px";
          emailSignatureDiv.appendChild(titleDiv);
        }

        // Add pronouns if available
        if (this.userData.proNouns) {
          const pronounsDiv = document.createElement("div");
          pronounsDiv.textContent = `'${this.userData.proNouns}'`;
          pronounsDiv.style.fontFamily = "Arial, sans-serif";
          pronounsDiv.style.color = "#043c4b";
          pronounsDiv.style.fontSize = "10.5pt";
          pronounsDiv.style.paddingLeft = "0px";
          emailSignatureDiv.appendChild(pronounsDiv);
        }

        // Add strapline if available
        if (this.userData.title) {
          const straplineDiv = document.createElement("div");
          straplineDiv.innerHTML = `<i>${this.userData.title}</i>`;
          straplineDiv.style.fontFamily = "Arial, sans-serif";
          straplineDiv.style.color = "#4296f8";
          straplineDiv.style.fontSize = "9pt";
          straplineDiv.style.paddingLeft = "0px";
          emailSignatureDiv.appendChild(straplineDiv);
        }

        // Add note if available
        if (this.userData.note) {
          const noteDiv = document.createElement("div");
          noteDiv.innerHTML = `${this.userData.note}`;
          noteDiv.style.fontFamily = "Arial, sans-serif";
          noteDiv.style.fontSize = "8pt";
          noteDiv.style.paddingLeft = "0px";
          noteDiv.style.width = "375px";
          noteDiv.style.maxwidth = "375px";
          emailSignatureDiv.appendChild(noteDiv);
        }

        // Add company logo
        const logoDiv = document.createElement("div");
        logoDiv.innerHTML = `<a href="https://www.dooh.com" style="border: none; text-decoration: none;"><img width="150px" height="56px" alt="DOOH.COM" src="${urlOrigin}/logo/DOOH.apng" style="border:0px none"></a>`;
        logoDiv.style.paddingLeft = "0px";
        emailSignatureDiv.appendChild(logoDiv);

        // Add address
        const addressDiv = document.createElement("div");
        addressDiv.innerHTML = this.currentAddress;
        addressDiv.style.fontFamily = "Arial, sans-serif";
        addressDiv.style.color = "#043c4b";
        addressDiv.style.fontSize = "10.5pt";
        addressDiv.style.paddingLeft = "0px";
        emailSignatureDiv.appendChild(addressDiv);

        // Add mobile
        if (this.userData.contactCard.phoneNumber) {
          const phoneNumber = this.userData.contactCard.phoneNumber || "";
          const cleanedMobile = phoneNumber.replace(/[^\d\+]/g, "");
          const displayMobile = phoneNumber.replace(/.{4}/g, "$&\u200B");
          const mobileDiv = document.createElement("div");
          mobileDiv.innerHTML = `
                                  <span style="color:rgb(0,167,193)">M:</span>
                                  <a href="tel:${cleanedMobile}" style="font-size:10.5pt; font-family:Arial, sans-serif; color:#043c4b; text-decoration: none !important;">
                                      <span style="font-size:10.5pt; font-family:Arial, sans-serif; color:#043c4b; text-decoration: none !important;">${displayMobile}</span>
                                  </a>`;
          mobileDiv.style.fontFamily = "Arial, sans-serif";
          mobileDiv.style.color = "#043c4b";
          mobileDiv.style.fontSize = "10.5pt";
          mobileDiv.style.paddingLeft = "0px";
          mobileDiv.style.paddingTop = "10px";
          emailSignatureDiv.appendChild(mobileDiv);
        }

        // Add banner
        const bannerDiv = document.createElement("div");
        bannerDiv.innerHTML = `
                                <a href="https://www.dooh.com" style="border: none; text-decoration: none;">
                                    <img width="375px" height="91px" alt="banner" src="${this.getBannerUrl()}" style="border:0px none">
                                </a>`;
        bannerDiv.style.paddingTop = "10px";
        bannerDiv.style.paddingLeft = "0px";
        bannerDiv.style.paddingBottom = "5px";
        emailSignatureDiv.appendChild(bannerDiv);

        // Add social media links
        const socialMediaDiv = document.createElement("div");
        socialMediaDiv.innerHTML = `
                                Follow us on:
                                <a href="http://www.x.com/WeAreDOOH" style="border: none; text-decoration: none;"><img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="X" src="${urlOrigin}/socials/x-2.png"></a>
                                <span style="font-size: 14pt; padding-left: 10px; padding-right: 10px; vertical-align: middle;">•</span>
                                <a href="https://www.linkedin.com/company/doohcom/" style="border: none; text-decoration: none;"><img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="LinkedIn" src="${urlOrigin}/socials/linkedin.png"></a>
                                <span style="font-size: 14pt; padding-left: 10px; padding-right: 10px; vertical-align: middle;">•</span>
                                <a href="https://www.instagram.com/dooh_com/" style="border: none; text-decoration: none;">
                                    <img style="width:20px; height:20px; vertical-align: middle;" width="20" height="20" alt="Instagram" src="${urlOrigin}/socials/instagram.png">
                                </a>`;
        socialMediaDiv.style.fontFamily = "Arial, sans-serif";
        socialMediaDiv.style.color = "#043c4b";
        socialMediaDiv.style.textAlign = "left";
        socialMediaDiv.style.paddingLeft = "0px";
        socialMediaDiv.style.paddingTop = "10px";
        emailSignatureDiv.appendChild(socialMediaDiv);

        // Add disclaimer
        const disclaimerDiv = document.createElement("div");
        disclaimerDiv.innerHTML = `
                                This e-mail message is confidential, intended only for the named recipient(s) above and may contain information that is privileged or exempt from disclosure under applicable law. If you have received this message in error, or are not the named recipient(s), please immediately notify the sender via reply email and delete this email message from your computer.
                            `;
        disclaimerDiv.style.fontFamily = "Arial, sans-serif";
        disclaimerDiv.style.color = "#043c4b";
        disclaimerDiv.style.fontSize = "9.5px";
        disclaimerDiv.style.paddingLeft = "0px";
        disclaimerDiv.style.paddingTop = "10px";
        disclaimerDiv.style.width = "375px";
        disclaimerDiv.style.maxwidth = "375px";
        emailSignatureDiv.appendChild(disclaimerDiv);

        emailSignature = emailSignatureDiv.outerHTML;
      } else {
        // Generate plain text version
        let plainTextSignature = "";
        plainTextSignature += `${this.userData.contactCard.firstName} ${this.userData.contactCard.surname}<br>`;
        if (this.userData.contactCard.jobTitle) {
          plainTextSignature += `${this.userData.contactCard.jobTitle}<br>`;
        }
        if (this.userData.proNouns) {
          plainTextSignature += `'${this.userData.proNouns}'<br>`;
        }
        if (this.userData.title) {
          plainTextSignature += `${this.userData.title}<br>`;
        }
        if (this.userData.note) {
          plainTextSignature += `${this.userData.note}<br>`;
        }
        plainTextSignature += `<br>DOOH.COM<br>`;
        plainTextSignature += `${this.currentAddress}<br><br>`;
        if (this.userData.contactCard.phoneNumber) {
          plainTextSignature += `M: ${this.userData.contactCard.phoneNumber}<br><br>`;
        }
        plainTextSignature += `Follow us on: <a href="http://www.x.com/WeAreDOOH">X</a> • <a href="https://www.linkedin.com/company/doohcom/">LinkedIn</a> • <a href="https://www.instagram.com/dooh_com/">Instagram</a><br><br>`;
        plainTextSignature += `This e-mail message is confidential, intended only for the named recipient(s) above and may contain information that is privileged or exempt from disclosure under applicable law. If you have received this message in error, or are not the named recipient(s), please immediately notify the sender via reply email and delete this email message from your computer.`;

        emailSignature = plainTextSignature;
      }

      this.previewSignature = emailSignature;
      if (displaySnackbar) {
        // Copy email signature to clipboard as rich text
        const tempElement = document.createElement("div");
        tempElement.innerHTML = emailSignature;
        document.body.appendChild(tempElement);

        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        selection.removeAllRanges();
        selection.addRange(range);

        const successful = document.execCommand("copy");
        if (successful) {
          this.$root.$emit(
            "snackbarSuccess",
            "Email signature successfully copied to clipboard!"
          );
        } else {
          this.$root.$emit(
            "snackbarError",
            "Failed to copy email signature to clipboard."
          );
        }

        document.body.removeChild(tempElement);
      }
      this.updatePreview();
    },

    getBannerUrl() {
      const urlOrigin = window.location.origin + "/assets/branding/signature";
      let src = `${urlOrigin}/banners/${this.selectedImage}.png`;
      return src;
    },

    async getUser() {
      this.loaded = false;
      await UserController.whoAmI()
        .then((res) => {
          this.userData = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<style scoped>
.banner-box {
  outline: solid 1px var(--v-sidebarColorLight-lighten1);
  transition: outline 250ms;
}

.selected-img {
  outline: solid 3px var(--v-primary-base);
  background-color: var(--v-primary-base);
}

.preview-box {
  width: 100%;
  background: white;
  color: black;
  height: 100%;
  border-radius: 4px;
  outline: var(--v-sidebarColorLight-lighten1) solid 1px;
  overflow: auto;
  padding: 10px;
}
</style>